import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Navbar, Nav, Form, FormControl, Button, Col } from "react-bootstrap"
import styled from 'styled-components';

const CenteredLogo = styled.span`
  padding: 5px;
`;

const CustomNavbar = ({ pageInfo }) => {
  console.log(pageInfo)
  return (
    <>
      <Navbar bg="nav-color" variant="dark" expand="lg" id="site-navbar">
        {/* <Container> */}
        <Link to="/" className="link-no-style">
          <Navbar.Brand as="span">
              
          <StaticImage
      className="logo"
      src="../images/white-k.png"
      height={50}
      quality={95}
      placeholder="blurred"
      alt="logo"
    />    
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Col md={{ span: 2, offset:  11 }}>
          <Nav activeKey={pageInfo && pageInfo.pageName}>
            <Link to="/about" className="link-accent">
              <Nav.Link as="span" eventKey="about">
                About Me
              </Nav.Link>
            </Link>
          </Nav>
          </Col>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </>
  )
}

export default CustomNavbar