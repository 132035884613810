import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Navbar from "./navBar"
const Layout = ({ location, title, children, pageInfo }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <>
      <StaticImage
      className="logo"
      src="../images/logo.png"
      height={300}
      quality={95}
      placeholder="blurred"
      alt="Profile picture"
    />
    
      </>
    )
  } 
  // else {
  //   header = (
  //     <Link className="header-link-home" to="/">
  //       {title}
  //     </Link>
  //   )
  // }

  return (
    <>
    <Navbar pageInfo={pageInfo} />
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Krishna Halaharvi
      </footer>
    </div>
    </>
  )
}

export default Layout
